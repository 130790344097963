@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .counterOfferModalFormSubmitButton {
    margin-top: md;
    width: 100%;
  }

  .counterOfferModalFormTipText {
    margin-top: md;
  }

  .counterModalOfferFormShippingText {
    text-transform: lowercase;
  }
}
