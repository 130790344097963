@value xs, sm, md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .offerHistoryContainer {
    display: flex;
    align-self: flex-start;
    margin: md 0;
  }

  .offerHistoryProgressBarContainer {
    margin-right: md;
  }

  .offerHistoryValuesContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .offerHistoryValues {
    display: flex;
    gap: xs;
    align-items: flex-start;
  }

  .offerHistoryValuesCaption {
    line-height: 1.8;
  }
}
